export const menuItems = [
  {
    name: "home",
    label: "Home",
   
  },
  {
    name: "about",
    label: "About Us",
   
  },
  {
    name: "services",
    label: "Services",
   
  },
 {
    name: "contact",
    label: "Contact",
   
  },
  
];
